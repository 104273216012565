@import '../../assets/scss/base.scss';

.bulkreplay {
  padding: 10px;
  .replay-title label {
    font-size: 15px!important;
    font-weight: $font-weight-semibold;
  }
  .replay-subtitle label{
    font-size: 11px!important;
  }
  .custom-file {
    font-size: $font-size-md!important;
    margin-bottom: 0;
  }
  .custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .6rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: $input-bg-color;
    border: 1px solid convertToRgba($black, .15);
    border-radius: .25rem;
  }
  .custom-file-control:lang(en)::before {
    content: "Browse";
  }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: .6rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #eceeef;
    border: 1px solid convertToRgba($black, .15);
    border-radius: 0 .25rem .25rem 0;
  }
  .limit_excel{
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
  }
  .limit_pos{
    position: relative;
    top: 13px;
  }
  .p20{
    padding-left: 20px;
  }
}
