@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./base.scss";

// User Agent disable
table {
    display: table;
    border-collapse: unset;
    white-space: unset;
    line-height: unset;
    font-weight: unset;
    font-size: unset;
    font-style: unset;
    color: unset;
    text-align: unset;
    border-spacing: 2px;
    border-color: grey;
    font-variant: normal;
}

.align-center {
    text-align: center;
}

.align-left {
    float: left;
}

.align-right {
    float: right;
}

.pl35 {
    padding-left: 35px;
}

.bottom5 {
    margin-bottom: 5px;
}

.btn>i {
    margin-right: 5px;
}

.bottom20 {
    margin-bottom: 20px;
}

.bottom50 {
    margin-bottom: 50px;
}

.bottom100 {
    margin-bottom: 100px;
}

.left15 {
    margin-left: 15px;
}

.right15 {
    margin-right: 15px;
}

.right10 {
    margin-right: 10px;
}

.left5 {
    margin-left: 5px;
}

.top25 {
    margin-top: 25px;
}

.top20 {
    margin-top: 20px;
}

.top10 {
    margin-top: 10px;
}

.top12 {
    margin-top: 12px;
}

.top15 {
    margin-top: 15px;
}

.top50 {
    margin-top: 50px;
}

.pt5 {
    padding-top: 5px
}

.offset-right0 {
    padding-right: 0 !important;
}

.offset-left0 {
    padding-left: 0 !important
}

.radio-class {
    display: flex !important;
    margin-top: 0.5rem;
}

.btn {
    font-size: 13px !important;
    border: none;
    border-radius: 0.2rem;
}

.f18 {
    font-size: 18px !important;
}

.f16 {
    font-size: 16px !important;
}

.f15 {
    font-size: 15px !important;
}

.f14 {
    font-size: 14px !important;
}

.width50 {
    width: 50%;
}

.height100 {
    height: 100%;
}

.cle-btn {
    position: relative;
}

.cle-btn-icons {
    position: absolute;
}

.cle-pointer {
    cursor: pointer;
}

.card-title,
.modal-title {
    font-family: 'Helvetica' !important;
    text-transform: capitalize !important;
}

.btn:focus {
    outline: 0 !important;
    box-shadow: none
}

.lh40 {
    line-height: 40px;
}

.btn-log-right {
    right: 10.8%
}

.btn-log-right-full {
    right: 5.8%;
}

.rdtPicker {
    border: 1px solid #e2dcdc !important
}

.cle-btn-danger {
    background-color: $app-error-color;
    color: #fff;
    &:hover {
        background-color: #f54335;
    }
}

.cle-btn-danger.disabled {
    background-color: $app-error-color;
}

.cle-btn-success {
    background-color: #5cb860;
    color: #fff;
    &:hover {
        background-color: #50c155;
    }
}

.cle-btn-success.disabled {
    background-color: #5cb860;
}

.cle-btn-warning {
    background-color: #EB7B30;
    color: #fff;
    &:hover {
        background-color: #ea7222;
    }
}

.cle-btn-warning.disabled {
    background-color: #EB7B30;
}

.cle-btn-info {
    background-color: #51bcda;
    color: #fff;
    &:hover {
        background-color: #2ba9cd !important;
        color: #fff !important;
        box-shadow: none !important;
    }
}

.cle-btn-filter {
    background-color: #b365b3;
    color: #fff;
    &:hover {
        background-color: #b151b1;
        box-shadow: none !important;
    }
}

.cle-btn-filter.disabled {
    background-color: #b983b9;
}

.cle-btn-dark {
    background-color: #343a40;
    color: #fff;
}

.modal-title {
    color: #0a3569;
    // margin-left: auto;
}

.cle-datepicker input,
input.text-filter {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.error input {
    border-color: $app-error-color;
}

.cle-datepicker table thead tr:first-child,
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #2a5788 !important;
    color: #fff;
}

.cle-datepicker thead tr:first-child th:hover {
    background-color: #2a5788 !important;
}

.rdtPicker td.rdtToday:before {
    border-bottom: 7px solid #2a5788 !important;
}

.cle-mandatory {
    color: $app-error-color;
}

.css-1492t68 {
    font-size: 12px;
}

.css-2o5izw {
    box-shadow: none !important;
    border: 1px solid #4c80bd !important;
}

.has-error input {
    border: 1px solid red;
}

.has-error {
    border: 1px solid #f55a4e !important;
}

.tooltip-inner {
    background-color: #295586 !important;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #295687;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #295687 !important;
}

.cle-form {
    input[type=text],
    input[type=search],
    .cle-select {
        width: 80%;
        font-size: 13px;
        height: 90%;
        margin-left: 2%;
    }
}

.cle-form select {
    width: 80%;
    font-size: 13px;
    margin-left: 2%;
}

.cle-multi-select {
    height: 100% !important
}

:root {
    --cle-blue: #2a5788
}

.cle-header {
    font-size: 23px;
    color: var(--cle-blue)
}

.label-header {
    font-size: small;
    padding-right: 9px;
    position: relative;
    top: 1.48px;
}

.cle-header {
    .icon-right {
        padding: 0 10px 0 10px;
    }
    .icon-left {
        padding-right: 20px;
    }
    .icon-right-last {
        padding-left: 10px
    }
}

.pointer {
    cursor: pointer;
}

.cle-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 940px !important;
    }
}

.form-error {
    color: $app-error-color;
    font-size: 10px;
    position: absolute;
    margin-top: -5px;
}

.fade-in {
    transition: opacity .25s ease-in-out !important;
    -moz-transition: opacity .25s ease-in-out !important;
    -webkit-transition: opacity .25s !important;
}

.fa-fullscreen:before {
    content: "\F0B2";
}

.b500 {
    font-weight: 500;
}

.btndisable {
    opacity: 0.5;
    pointer-events: none;
}

@media (min-width: 1200px) {
    .container {
        max-width: 100%;
    }
}

.modal-content {
    border: 0px;
}

.close {
    font-size: 35px;
    padding: 10px 10px 5px 5px !important;
    font-weight: 100 !important;
    border: none !important;
    outline: none !important;
}

.minimize {
    position: relative;
    right: 10px;
    top: 18px;
    font-size: 18px;
}

.modal-header {
    padding: 1rem 1rem 5px 1rem;
}

.modal-footer {
    border: 0px;
    padding: 1rem 1rem 10px 1rem;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}

.custom-radio {
    margin-right: 2rem;
}

.label-class {
    padding-top: 0.15rem;
}

.cus_disabled {
    pointer-events: none;
    opacity: 0.8;
}

.cus_notallowed {
    cursor: not-allowed !important;
}

.icn_pre_nxt {
    padding: 0 5px;
    position: relative;
    /* top: -2px; */
    font-size: 20px;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
    border-color: #adb5bd;
    cursor: no-drop;
}

.disableTime {
    color: #9a9a9a !important;
    cursor: no-drop;
}

.zIndexTop {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.details {
    overflow-x: hidden;
    word-break: break-word;
    font-family: $primary-font-family;
    font-size: .95em;
}

.modal-with-pagination {
    .ant-modal-title {
      display: flex;
  
      .pagination {
        margin: 0 auto;
        margin-right: 430px;
      }
    }
    
    .detail_label {
        line-height: 30px;
    }
}

.initLoader {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .anticon-loading {
        font-size: 75px;
        color: #00BFFF;
    }
}

.bold-text {
    font-weight: bold;
}