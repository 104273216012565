@import '../../../assets/scss/base.scss';

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: $drawer-bg-color;
  border-top: 1px solid $drawer-border-color;
}

.ant-drawer-header {
  position: relative;
  margin-bottom: 60px;

  .ant-drawer-title{
    font-weight: $font-weight-semibold;
  }

  .ant-drawer-close {
    position: relative;
    left: -5px;
  
    &:focus {
      outline: none;
    }
  }

  .search-tags {
    position: absolute;
    left: 25px;
    right: 0;
    top: 73px;
    width: 88%;
  }
}

.ant-drawer-body {
  border-top: 1px solid #d9d9d9;
  padding: 0px 25px !important;

  .ant-list-item {
    padding: 8px 0;
  }

  .ant-list-item-meta-title{
    margin: 5px 0 10px;
    font-size: $font-size-md;
    font-weight: $font-weight-semibold;
  }
  .ant-list-item-meta-content .ant-list-item-meta-description .ant-tag{
    margin-bottom: 8px;
    cursor: pointer;
    white-space: normal;
    max-width: 330px;
    padding: 3px 9px;
    border-radius: 15px;
    font-size: $font-size-sm;
    word-break: break-all;
  }
}
.ant-tag-processing svg {
  color: $blue-light-100;
}
