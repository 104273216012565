.mt15 {
    margin-top: 15px;
}

span[disabled] {
    cursor: not-allowed !important;
}

.model-header-icon {
    font-size: 25px;
    color: #3F51B5;
}

.ml-10 {
    margin-left: 10px;
}
.mr5 {
    margin-right: 5px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr50 {
    margin-right: 50px;
}

.f2em {
    font-size: 2em;
}

.justify-content-end{
    justify-content: end;
}

.admin-modal-wrapper .ant-modal-body{
    max-height: 70vh;
}
.admin-modal-wrapper .ant-modal-body .ant-form-item{
    margin-bottom: 5px;   
}
.admin-modal-wrapper .ant-modal-body .ant-form-item-label > label{
    color:rgba(0, 0, 0, 0.85);
}

/* .admin-modal-wrapper.ant-modal{
    top:15vh;
} */

.admin-modal-wrapper .ant-modal-title{
    color: #0a3569;
    font-size: 20px;
}

.admin-modal-wrapper .ant-input-number{
    width: 100%;
}

.admin-cle-table-wrapper{
    padding: 10px;
}

.anticon svg {
    display: flex !important;
  }

.cost-analysis-form-header{
    margin: 20px;
    color: #023a6b;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}
.cost-analysis-form.ant-form{
    margin : 20px;
}
.cost-analysis-form .ant-form-item-label>label{
    padding-right: 15px;
}