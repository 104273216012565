.modal-body {
  &.indexConfig {
    span {
      &.ant-input-affix-wrapper:focus,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-affix-wrapper:hover  {
        border-color: #d9d9d9;
      }
    }
  }
}

.index-detail {
  .ant-modal-body {
    padding: 10px 24px;
  }
}
