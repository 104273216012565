@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-direction($direction) {
    @if $direction == column {
        -webkit-flex-direction: vertical;
        -moz-flex-direction: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
    } @else {
        -webkit-flex-direction: horizontal;
        -moz-flex-direction: horizontal;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
    }
}

@mixin flex-flow(
    $values: (
        row nowrap,
    )
) {
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

// flex-start | flex-end | center | baseline | stretch
@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}
