@import "../../../assets/scss/base.scss";

.user-detail-modal {
    .ant-modal-title {
        font-size: $font-size-md;
        @include flexbox();
    }

    .user-detail-row {
        padding: 5px 10px;
        width: 100%;
        margin: auto;
    }
    
    .user-detail-col {
        display: inline-block;
        width: 90px;
    }
    
    .user-detail-val {
        display: inline-block;
    }
    
    .user-detail-field-row {
        padding: 6px 15px;
    }
}

.adduser-form {
    label {
        margin-left: 25px;
    }

    select {
        width: 85% !important;
        height: 95% !important;
    }

    input {
        width: 85% !important;
    }
}

.copy-modal {
    position: relative;
    top: 20px;
}

.add-user-icon, .remove-icon, .edit-icon {
    color: $white;
    font-size: $font-size-xl;
}

.modal-body-gpid {
    display: flex;
    position: relative;
    top: 10px;
}

.modal-body-overflow {
    overflow: hidden;
    margin-top: -2%;
}

.access-list-box {
    overflow-y: auto;
    box-sizing: border-box;
    max-height: calc(100vh - 390px);
    width: 100%;
}

.col2 {
    position: relative;
    top: 3px;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}