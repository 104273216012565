.contentInner {
  position: relative;
  margin-left: 20px;
  width: 98%;
}

.noModal {
  padding: 15px;
}

.grayBackground {
  background-color: #e2e2e21f;
}


@media (max-width: 767px) {
  .contentInner {
    padding: 12px;
    min-height: calc(100vh - 160px);
  }
}

.pageLoader {
  position: relative;
}