@import '../assets/scss/base.scss';

.text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-gradient {
    background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.background-hover {
    transition: all 0.3s ease-out;
}

.background-hover:hover {
    background-color: #f9f9fc;
}

.backTop {
    right: 50px;
}

.backTop .ant-back-top-content {
    background: $blue-light-100;
    opacity: 0.3;
    transition: all 0.3s;
    box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);
}

.backTop .ant-back-top-content:hover {
    opacity: 1;
}

.content {
    padding: 24px;
    min-height: calc(100% - 72px);
}

.container {
    height: 100vh;
    -ms-flex: 1 1;
    flex: 1 1;
    width: calc(100% - 256px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.footer {
    background: $white;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 72px;
}

@media (max-width: 767px) {
    .content {
        padding: 12px;
    }

    .backTop {
        right: 20px;
        bottom: 20px;
    }

    .container {
        height: 100vh;
        -ms-flex: 1 1;
        flex: 1 1;
        width: 100%;
    }
}

.main-container {
    position: relative;
    top: 94px;
}

.cle-new-font {
    font-family: $primary-font-family;
}

.content-container {
    background-color: $white;
    height: calc(100vh - 94px);
}