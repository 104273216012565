@import "../../../assets/scss/base.scss";

.footer-container {
    box-shadow: 0 0px 4px 0px convertToRgba($black, .1);
    padding: 15px;
    border-radius: 5px;
}

.result-label {
    font-weight: $font-weight-bold;
}