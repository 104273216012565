// CLE Theme Color Variables
$primary-color: #2a5788;
$secondary-color: #f0f0f0;

$white: #ffffff;
$black: #000000;
$blue: #3f51b5;
$blue-light-100: #1890ff;
$blue-light: #1f74bf;
$red: #f55a4e;
$red-100: #fc7d7de3;
$red-200: #F44336;
$red-dark: #ff4d4f;


$gray-100: #f0f0f0;
$gray-200: #f0f0f0;
$gray-300: #e9e9e9;
$gray-400: #d4d4d4;
$gray-500: hsla(0,0%,96.9%,.37);
$gray-600: #9a9a9a;
$gray-700: #4e4e4e;

$green-100: #4caf50;
$green-200: #18ce0f;
$green-300: #1beb11;
$green-400: #18ce0f;

$yellow-100: #FFC107;

$app-text-color: $black;
$app-success-color: $green-200;
$app-error-color: $red;
$app-warning-color: $green-200;

$app-bg-black: $black;
$app-bg-white: $white;

$border-width: 1px;
$border-radius: 1px;

// Form Elements (Input, Select, MultiSelect Variables)
$input-bg-color:  $white;
// $input-border-color: ;
// $input-hover-border-color: ;
// $input-text-color: ;
$input-text-font-size: $font-size-md;
// $input-text-font-weight: ;

$form-label-font-size: $font-size-md;
$form-label-font-weight: $font-weight-regular;

$checkbox-radio-color: $blue;

// Primary Button Variables
$button-primary-background-color: $blue;
$button-primary-hover-background-color: $blue;
$button-primary-text-color: $white;
$button-primary-text-font-size: $font-size-md;
$button-primary-text-font-weight: $font-weight-regular;

// Secondary Button Variables
$button-secondary-background-color: $blue;
$button-secondary-hover-background-color: $blue;
$button-secondary-text-color: $white;
$button-secondary-text-font-size: $font-size-md;
$button-secondary-text-font-weight: $font-weight-regular;

// Header Navbar Variables
$header-bg-color: $white;
$header-item-text-color: $white;
// $header-item-text-font-size: ;
// $header-item-text-font-weight: ;

// Table
$table-header-label-color: $gray-700;
$table-header-bg-color: $white;
$table-header-text-color: $blue-light;
$table-header-text-font-size: $font-size-md;
$table-header-text-font-weight: $font-weight-medium;
$table-header-box-shadow: rgba(0, 0, 0, 0.1);
$table-row-error-status-bg-color: $red-100;
$table-row-error-status-text-color: $white;
$table-row-bg-color: $white;
$table-row-hover-bg-color: $gray-500;
$table-row-selected-bg-color: $gray-500;
$table-row-text-color: #4e4848;
$table-row-text-font-size: $font-size-md;
$table-icon-color: $green-100;

// Tabs
$tabs-border-color: $gray-100;
$tabs-border-width: $border-width;
$tabs-border-radius: $border-radius;
$tabs-nav-bg: $white;
$tabs-nav-hover-border-color: $gray-100;
$tabs-nav-font-size: $font-size-md;
$tabs-nav-active-text-color: $white;
$tabs-nav-active-bg: $primary-color;

// Drawer
$drawer-bg-color: $white;
$drawer-border-color: $gray-300;