@import "../../../assets/scss/base.scss";

.modalTitle {
    .ant-btn {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }

    .btnWidth {
        width: 110px;
    }

    .btnHeaderLeftIcon {
        font-size: 1.2em;
        vertical-align: middle;
        color: $blue;
    }

    .headerTitle {
        font-size: 1.2em;
        font-weight: $font-weight-semibold;
        color: #0a3569;
        position: relative;
        left: 10px;
    }

    .headerRightEdIcon {
        margin-right: 15px;
        font-size: 1.2em;
        color: $yellow-100;
    }

    .headerCloseIcon {
        font-size: 1.2em;
        color: $red-200;
        position: relative;
        top: -4px;
    }
}