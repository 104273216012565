.line-item-header{
    margin: 5px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.line-item-header-text{
    font-size: 1.5em;
    color: #9e9e9e;
    font-weight: 500;
}

.line-item-list-wrapper{
    padding:  5px 10px;
    padding-top:0px;
    max-height: calc(70vh - 70px);
    overflow: auto;
}

.line-item-list-wrapper .line-item-row{
    padding: 10px;
}

.line-item-list-wrapper .line-item-row .access-wrapper{
    display: flex;
    justify-content: space-between;
}


.line-item-list-wrapper .icon-wrapper{
    justify-content: center;
}
