.message-metrics-wrapper {
    padding: 0 5px;
    .ant-tabs-extra-content {
        margin-bottom: 4px;
    }
}

.d-none {
    display: none;
}

.content-message {
    .ant-select-multiple .ant-select-selection-item-remove {
        line-height: 22px;
    }
    .ant-select-multiple.ant-select-lg .ant-select-selection-item {
        height: 32px;
        line-height: 29px;
    }
}