.create-user-container {
    width: 90%;
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding-top: 0px;
    margin: auto;
    padding: 0 20px 10px;
    margin-top: 20px;
    margin-bottom: 70px;
}

.create-user-header {
    padding: 15px 0px;
    font-size: 20px;
    font-weight: 500;
    color: #9E9E9E;
}

.line-item-header {
    font-size: 15px;
    padding-bottom: 10px;
    color: #9E9E9E;
    font-weight: 500;
}

.line-item-row {
    border: 1px solid #efefef;
    padding: 5px 10px 5px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
}

.add-item-container {
    padding-top: 5px;
    display: inline-block;
}

.add-item-button {
    font-size: 17px;
    color: #4CAF50;
    padding-right: 5px;
}

.add-item-txt {
    font-size: 13px;
    color: #9E9E9E;
}

.item-col2 {
    position: relative;
    top: 7px;
}

.action-icon-container {
    position: relative;
    top: 10px;
    text-align: right;
    /* right: 15px; */
}

.action-icon-edit {
    font-size: 18px;
    color: #0b7710;
    margin-right: 15px;
}

.action-icon-delete {
    font-size: 18px;
    color: #ff0808;
}

.line-item-error {
    border: 1px solid #ff0808;
}

.error-item-msg {
    color: #ff0808
}

.p10 {
    padding: 10px;
}

.application_access_conatiner {
    padding: 10px 0;
}

.no_record_role {
    font-size: 18px;
    color: #9E9E9E;
    font-weight: 500;
    text-align: center;
    padding: 30px;
}
.role-access-wrapper{
    max-height: calc(40vh - 20px);
    overflow: auto;
    padding: 5px;
}
.role-access-wrapper > div{
    padding: 10px;
}
.role-access-wrapper .ant-col{
    max-width: 100%;
}

.role-access-wrapper .user-access-checkbox-group{
    display: flex;
    justify-content: space-between;
}