@import '../../../assets/scss/base.scss';

.disabledAll{
    cursor: not-allowed;
    opacity: 0.5;
    span{
        pointer-events: none;
    }
}

.modify-btn{
    background-color: rgb(53, 92, 138) !important;
    border: rgb(53, 92, 138) !important;
    color: $white;
}

.modify-btn[disabled]{
    background-color:rgb(30, 63, 103) !important;
    border: rgb(30, 63, 103) !important;
    color: $white !important;
    opacity: 0.65;
}
.detail-btn{
    background-color: rgb(53, 92, 138) !important;
    border: rgb(53, 92, 138) !important;
    color: $white;
}

.detail-btn[disabled]{
    background-color:rgb(30, 63, 103) !important;
    border: rgb(30, 63, 103) !important;
    color: $white !important;
    opacity: 0.65;
}
.delete-btn[disabled]{
    color: $white !important;
    background: $red-dark !important;
    border-color: $red-dark !important;
    opacity: 0.65;
}