.passwordLabelWrapper {
    .ant-input-password {
        &.ant-input-affix-wrapper,
        &.ant-input-affix-wrapper:hover,
        &.ant-input-affix-wrapper:focus {
            border: none;
            box-shadow: none;
            input {
                caret-color: rgba(0, 0, 0, 0);
            }
        }
    }
}

.config-detail-modal{
    .ant-row{
        margin-bottom: 15px;
    }
}


.config-modal-header{
  display: flex;
  width: 50%;
  justify-content: space-between;
}