.collapse-header {
  .ant-collapse-header {
    font-size: 16px;
    font-weight: 600;
    color: #a5a5a5;
  }

  .ant-collapse-content-box {
    position: relative;

    .clipboard {
      position: absolute;
      top: 20px;
      right: 0;
      // display: none;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .clipboard {
        // display: block;
        opacity: 1;
        right: 60px;
      }
    }
  }
}

.message-content {
  .ant-message-notice-content {
    padding: 0;
  }
}

.status-icon {
  position: relative;
  top: -2px;
  left: 4px;
}

.collapse-content {
  max-height: 300px;
  overflow: auto;
  padding-left: 20px;
  border: 1px solid #efefef;
  outline: none;
}

.no-data-msg {
  padding: 15px;
  text-align: center;
  font-size: 1.5em;
}

.tbl-blob-blue {
  color: #222;
  // border: 1px solid #9e9e9e;
  margin: auto;
  width: 100%;
  font-size: 0.95em;

  thead {
    // background-color: #9e9e9e;
    // color: #fff;
    tr {
      border-bottom: 1px solid #dcdcdc;
      text-align: center;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dcdcdc;
    }

    th {
      width: 100px;
    }
  }

  th,
  td {
    padding: 4px 10px;
  }

  td {
    padding: 4px 25px;
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 15em;
    word-break: break-all;
  }
}

.ag-theme-height > :first-child {
  height: 86% !important;
}
.ant-btn.copy-clip-board,
.ant-btn.copy-clip-board:hover {
  position: absolute;
  right: 15px;
  top: 3px;
  border: 0;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #e2e2e2;
  opacity: 0.65;
  cursor: pointer;
  color: #222;
}
.react-json-view {
  white-space: pre-wrap;
}
