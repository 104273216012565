.form-medium-antd-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 38px;
    padding: 4px 11px;
}

.form-medium-antd-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 35px;
}

.select-dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 4px; 
}