@import '../../assets/scss/base.scss';

.sider {
    box-shadow: rgba(24, 144, 255, 0.1) 0 0 28px 0;
    z-index: 10;
}

.sider .ant-layout-sider-children {
    @include flexbox();
    @include flex-direction(column);
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.brand {
    z-index: 1;
    height: 72px;
    @include flexbox();
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 24px;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
    /* background-color: #fcfcfc; */

    .logo {
        @include flexbox();
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;

        img {
            width: 50px;
            margin-right: 8px;
        }

        h1 {
            vertical-align: text-bottom;
            font-size: 16px;
            display: inline-block;
            font-weight: 700;
            color: $blue-light-100;
            white-space: nowrap;
            margin-bottom: 0;
            background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: fadeRightIn 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            animation-fill-mode: both;
        }
    }
}

.menuContainer {
    height: calc(100vh - 94px);
    overflow-x: hidden;
    overflow-y: overlay;
    padding-top: 7px;
    scroll-behavior: smooth;

    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
        cursor: pointer;
    }

    .ant-collapse-item-disabled {
        .menu-link {
            color: $gray-400;
            padding: 7px 0;
    
            &.active {
                color: $white;
                background: $primary-color;
            }
        }
    }
    

    .ant-menu-inline {
        border-right: none;
    }
}

.ant-layout-sider-collapsed .menuContainer .menu-link {
    @include flexbox();
    @include flex-direction(column);
    color: $gray-400;
    font-size: 0.8em;
}

.ant-open-menu .menuContainer {
    .menu-link, .ant-collapse-header-text > span {
        width: 100%;
        display: inline-block;
        padding: 5px 20px;
    }
}

.ant-layout-sider-collapsed .menuContainer .menu-link .menu-title {
    line-height: 18px;
    margin-top: 7px;
    font-size: 1em;
}

.ant-layout-sider-collapsed .menuContainer .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon+span {
    opacity: 1;
    display: block;
    margin-left: 0;
}

.ant-layout-sider-collapsed .menuContainer .ant-menu-item .anticon+span {
    margin-left: 0;
}

.ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
}

.menuContainer::-webkit-scrollbar-thumb {
    background-color: #808080;
}

.menuContainer::-webkit-scrollbar {
    display: none;
}

.menuContainer:hover::-webkit-scrollbar {
    display: block;
}

.switchTheme {
    width: 100%;
    height: 48px;
    @include flexbox();
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    transition: all 0.3s;
}

.switchTheme span {
    white-space: nowrap;
    overflow: hidden;
    font-size: $font-size-sm;
}

.switchTheme .anticon {
    min-width: 14px;
    margin-right: 4px;
    font-size: $font-size-md;
}

@keyframes fadeLeftIn {
    0% {
        transform: translateX(5px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}