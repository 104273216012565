@import '../../../assets/scss/base.scss';

.content-container {
    .ant-tabs-top>.ant-tabs-nav {
        margin-bottom: 0;
    }
    .ant-tabs {
        margin: 10px 5px 5px;
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
        border-radius: .25rem .25rem 0 0;
        background-color: $tabs-nav-bg;
        font-size: $tabs-nav-font-size;
        border-color: transparent transparent $tabs-nav-hover-border-color;
        &:hover,
        &:focus {
            border-width: $tabs-border-width;
            color: convertToRgba($black, .85);
            border-color: $tabs-nav-hover-border-color;
        }
    }
    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
        background: $tabs-nav-active-bg;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $tabs-nav-active-text-color;
        font-weight: $font-weight-bold;
    }
    .tab-with-controls {
        &.ant-tabs-top>.ant-tabs-nav::before {
            width: 33%;
        }
        .ant-tabs-extra-content {
            width: 65%;
        }
    }
}