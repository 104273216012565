@import "../../../assets/scss/base.scss";

.modal-footer-row {
    .save-btn {
        background-color: $green-400;
        color: $button-primary-text-color;
        border-color: $green-400;
    }
    .ant-btn{
        display: flex;
        align-items: center;
    }
}

.modal-footer-row .save-btn:hover,
.save-btn:focus,
.save-btn:active {
    background-color: $green-300;
    border-color: $green-300;
    color: $button-primary-text-color !important;
}
