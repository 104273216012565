.solman-staging-tab{
  margin-top:-44px;
}
.solman-staging-button{
  margin-top: 12px;
}

.sap-holddays-align{
  // position: relative;
  // top: 12px;
  padding-top: 5px;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
