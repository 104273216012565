.select-field-modal-header {
    display: flex;
    justify-content: space-between;

    .modal-title {
        font-size: 1.25em;
    }

    .select-all-checkbox {
        font-size: 14px;
        font-weight: 600;
    }
}

.select-field-modal-footer {
    justify-content: end;

    .anticon svg {
        font-size: 16px;
    }

    .wrn_dwnd{
        font-weight: 600;
        padding-right: 20px;
    }
}