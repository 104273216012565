.header-menu-icon {
  font-size: 20px;
  padding: 0 30px;
}

.header-title {
  font-size: 1.7vw;
  font-weight: 700;
  margin: 7px;
  margin-left: 10px;
  color: #00529c;
  margin-bottom: 0px;
}

.subHeaderInfo {
  background: #112c44;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex: 0 0 100%;
  align-self: flex-start;
  position: fixed;
  top: 0;
  width: 100%;

  .dev-url-wrapper {
    position: absolute;
    left: 5px;
  }

  span {
    text-decoration: underline;
  }

  p {
    margin: 0;
    font-size: 13px;
  }

  a {
    font-weight: bold;
  }
}

@media only screen and (max-width: 1300px) {
  .disp-header-title {
    display: none !important;
  }
}
@media only screen and (min-width: 1300px) {
  .disp-header {
    display: none !important;
  }
}

.header-cle-logo {
  width: 150px;
}

.header.fixed {
  position: fixed;
  top: 30px;
  right: 0;
  /* width: calc(100% - 256px); */
  z-index: 29;
  transition: width 0.2s;
}

.autoCompleteLabel {
  font-size: 1.2em;
  /* padding-right: 10px; */
  padding-left: 20px;
  color: #607d8b;
}

.autoCompleteIcon {
  font-size: 1.2em;
  width: 25px;
  color: #2196f3;
}

.searchBar {
  width: 100%;
  padding-left: 20px;
}

.header .ant-menu-submenu-title {
  height: 72px;
}

.header .ant-menu-horizontal {
  line-height: 72px;
}

.header .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #1890ff;
  background-color: #f9f9fc;
}

.header .ant-menu {
  border-bottom: none;
  height: 72px;
}

.header .ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
}

.toggle-header-menu {
  margin: 0px 15px !important;
}

.right-home {
  margin-left: 65px;
  margin-right: 20px;
}

.right-user {
  margin-right: 20px;
  font-size: 20px;
}

.right-logout {
  margin-right: 20px;
  font-size: 20px;
  span{
    vertical-align: middle;
  }
}

.rightContainer {
  color: #2a5788;
  display: inline-flex;
  justify-content: space-between;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .button {
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.header .button:hover {
  color: #1890ff;
  background-color: #f9f9fc;
}

.iconButton {
  width: 48px;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.iconButton:hover {
  background-color: #f9f9fc;
}

.iconButton:hover .iconFont {
  color: #1890ff;
}

.iconButton + .iconButton {
  margin-left: 8px;
}

.iconButton .iconFont {
  color: #b2b0c7;
  font-size: 24px;
}

.notification {
  padding: 24px 0;
  width: 320px;
}

.notification .notificationItem {
  transition: all 0.3s;
  padding: 12px 24px;
  cursor: pointer;
}

.notification .notificationItem:hover {
  background-color: #f9f9fc;
}

.notification .clearButton {
  text-align: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.notification .clearButton:hover {
  background-color: #f9f9fc;
}

.notificationPopover .ant-popover-inner-content {
  padding: 0;
}

.notificationPopover .ant-popover-arrow {
  display: none;
}

.notificationPopover .ant-list-item-content {
  -ms-flex: 0 1;
  flex: 0 1;
  margin-left: 16px;
}

@media (max-width: 767px) {
  .header {
    width: 100% !important;
  }
}
.header-autocomplete .ant-select-single .ant-select-selector {
  display: block !important;
}
@keyframes shakeAnimation {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg);}
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg);}
  100% { transform: rotate(0deg); }
}
.new-ui-link{
  font-weight: bold;
  font-weight: bold;
  align-items: end;
  display: flex;
  margin-right: 50px;
  text-decoration: underline !important;
  cursor: pointer;
  animation-name: shakeAnimation;
  animation-duration: 0.35s;
  animation-iteration-count: 5;
  animation-timing-function: ease-in-out;
  color:#023a6b;
}
