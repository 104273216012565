.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
}

.animated.flipOutX,
.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
}

@-webkit-keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

/*
 * Container style
 */

.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}


.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active {
    background-color: #888;
    border-color: #888;
}

.btn.btn-simple {
    color: #888;
    border-color: #888;
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active {
    background-color: transparent;
    color: #979797;
    border-color: #979797;
    box-shadow: none;
}

.btn.btn-link {
    color: #888;
}

.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active {
    background-color: transparent;
    color: #979797;
    text-decoration: none;
    box-shadow: none;
}

.btn:hover,
.btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important;
}

.btn:active,
.btn.active,
.open>.btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
}

.btn .badge {
    margin: 0;
}

.btn.btn-icon {
    height: 2.375rem;
    min-width: 2.375rem;
    width: 2.375rem;
    padding: 0;
    font-size: 0.9375rem;
    overflow: hidden;
    position: relative;
    line-height: normal;
}

.btn.btn-icon.btn-simple {
    padding: 0;
}

.btn.btn-icon.btn-sm {
    height: 1.875rem;
    min-width: 1.875rem;
    width: 1.875rem;
}

.btn.btn-icon.btn-sm i.fa,
.btn.btn-icon.btn-sm i.now-ui-icons {
    font-size: 0.6875rem;
}

.btn.btn-icon.btn-lg {
    height: 3.6rem;
    min-width: 3.6rem;
    width: 3.6rem;
}

.btn.btn-icon.btn-lg i.now-ui-icons,
.btn.btn-icon.btn-lg i.fa {
    font-size: 1.325rem;
}

.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.btn.btn-icon:not(.btn-footer) i.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 1.5626rem;
    width: 24px;
}


.btn-success {
    background-color: #18ce0f;
    color: #FFFFFF;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success.active:focus,
.btn-success.active:hover,
.show>.btn-success.dropdown-toggle,
.show>.btn-success.dropdown-toggle:focus,
.show>.btn-success.dropdown-toggle:hover,
.show>.btn-success.btn[aria-haspopup="true"],
.show>.btn-success.btn[aria-haspopup="true"]:focus,
.show>.btn-success.btn[aria-haspopup="true"]:hover {
    background-color: #1beb11;
    color: #FFFFFF;
    box-shadow: none;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
    background-color: #18ce0f;
    border-color: #18ce0f;
}

.btn-success.btn-simple {
    color: #18ce0f;
    border-color: #18ce0f;
}

.btn-success.btn-simple:hover,
.btn-success.btn-simple:focus,
.btn-success.btn-simple:active {
    background-color: transparent;
    color: #1beb11;
    border-color: #1beb11;
    box-shadow: none;
}

.btn-success.btn-link {
    color: #18ce0f;
}

.btn-success.btn-link:hover,
.btn-success.btn-link:focus,
.btn-success.btn-link:active {
    background-color: transparent;
    color: #1beb11;
    text-decoration: none;
    box-shadow: none;
}

.btn-info {
    background-color: #355c8a;
    color: #FFFFFF;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info.active:focus,
.btn-info.active:hover,
.show>.btn-info.dropdown-toggle,
.show>.btn-info.dropdown-toggle:focus,
.show>.btn-info.dropdown-toggle:hover,
.show>.btn-info.btn[aria-haspopup="true"],
.show>.btn-info.btn[aria-haspopup="true"]:focus,
.show>.btn-info.btn[aria-haspopup="true"]:hover {
    background-color: #1c4677;
    color: #FFFFFF;
    box-shadow: none;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
    background-color: #1e3f67;
    border-color: #1e3f67;
}

.btn-info.btn-simple {
    color: #2CA8FF;
    border-color: #2CA8FF;
}

.btn-info.btn-simple:hover,
.btn-info.btn-simple:focus,
.btn-info.btn-simple:active {
    background-color: transparent;
    color: #4bb5ff;
    border-color: #4bb5ff;
    box-shadow: none;
}

.btn-info.btn-link {
    color: #2CA8FF;
}

.btn-info.btn-link:hover,
.btn-info.btn-link:focus,
.btn-info.btn-link:active {
    background-color: transparent;
    color: #4bb5ff;
    text-decoration: none;
    box-shadow: none;
}

.btn-warning {
    background-color: #FFB236;
    color: #FFFFFF;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning.active:focus,
.btn-warning.active:hover,
.show>.btn-warning.dropdown-toggle,
.show>.btn-warning.dropdown-toggle:focus,
.show>.btn-warning.dropdown-toggle:hover,
.show>.btn-warning.btn[aria-haspopup="true"],
.show>.btn-warning.btn[aria-haspopup="true"]:focus,
.show>.btn-warning.btn[aria-haspopup="true"]:hover {
    background-color: #ffbe55;
    color: #FFFFFF;
    box-shadow: none;
}

.btn-warning:not([data-action]):hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
    background-color: #FFB236;
    border-color: #FFB236;
}

.btn-warning.btn-simple {
    color: #FFB236;
    border-color: #FFB236;
}

.btn-warning.btn-simple:hover,
.btn-warning.btn-simple:focus,
.btn-warning.btn-simple:active {
    background-color: transparent;
    color: #ffbe55;
    border-color: #ffbe55;
    box-shadow: none;
}

.btn-warning.btn-link {
    color: #FFB236;
}

.btn-warning.btn-link:hover,
.btn-warning.btn-link:focus,
.btn-warning.btn-link:active {
    background-color: transparent;
    color: #ffbe55;
    text-decoration: none;
    box-shadow: none;
}

.btn-danger {
    background-color: #FF3636;
    color: #FFFFFF;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger.active:focus,
.btn-danger.active:hover,
.show>.btn-danger.dropdown-toggle,
.show>.btn-danger.dropdown-toggle:focus,
.show>.btn-danger.dropdown-toggle:hover,
.show>.btn-danger.btn[aria-haspopup="true"],
.show>.btn-danger.btn[aria-haspopup="true"]:focus,
.show>.btn-danger.btn[aria-haspopup="true"]:hover {
    background-color: #ff5555;
    color: #FFFFFF;
    box-shadow: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
    background-color: #FF3636;
    border-color: #FF3636;
}

.btn-danger.btn-simple {
    color: #FF3636;
    border-color: #FF3636;
}

.btn-danger.btn-simple:hover,
.btn-danger.btn-simple:focus,
.btn-danger.btn-simple:active {
    background-color: transparent;
    color: #ff5555;
    border-color: #ff5555;
    box-shadow: none;
}

.btn-danger.btn-link {
    color: #FF3636;
}

.btn-danger.btn-link:hover,
.btn-danger.btn-link:focus,
.btn-danger.btn-link:active {
    background-color: transparent;
    color: #ff5555;
    text-decoration: none;
    box-shadow: none;
}

.btn:disabled,
.btn[disabled],
.btn.disabled {
    filter: alpha(opacity=50);
    pointer-events: none;
}

.btn-link {
    border: 0;
    padding: 0.5rem 0.7rem;
    background-color: transparent;
}


/* .btn-lg {
  font-size: 1em;
  border-radius: 0.25rem;
  padding: 15px 48px; } */

.btn-lg.btn-simple {
    padding: 14px 47px;
}

.btn-sm {
    font-size: 14px;
    border-radius: 0.1875rem;
    padding: 5px 15px;
}

.btn-sm.btn-simple {
    padding: 4px 14px;
}

.btn-wd {
    min-width: 140px;
}

.btn-group.select {
    width: 100%;
}

.btn-group.select .btn {
    text-align: left;
}

.btn-group.select .caret {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

.btn-round {
    border-width: 1px;
    border-radius: 30px;
    padding-right: 23px;
    padding-left: 23px;
}

.btn-round.btn-simple {
    padding: 10px 22px;
}

.no-caret.dropdown-toggle::after {
    display: none;
}

.form-control::-moz-placeholder {
    color: #DDDDDD;
    opacity: 1;
    filter: alpha(opacity=100);
}

.form-control:-moz-placeholder {
    color: #DDDDDD;
    opacity: 1;
    filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
    color: #DDDDDD;
    opacity: 1;
    filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
    color: #DDDDDD;
    opacity: 1;
    filter: alpha(opacity=100);
}

.css-1aya2g8 {
    border-color: #ddd !important;
}

.form-control {
    /*background-color: transparent;*/
    border: 1px solid #ddd;
    color: #2c2c2c;
    /*line-height: normal;
  font-size: 0.8571em;*/
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control:focus {
    border: 1px solid #4c80bd;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}

.form-control:focus+.input-group-addon,
.form-control:focus~.input-group-addon {
    border: 1px solid #f96332;
    border-left: none;
    background-color: transparent;
}

.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-danger .form-control.form-control-success,
.has-danger .form-control.form-control-danger,
.has-success .form-control.form-control-success,
.has-success .form-control.form-control-danger {
    background-image: none;
}

.form-control+.form-control-feedback {
    border-radius: 0.25rem;
    font-size: 14px;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
}

.open .form-control {
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom-color: transparent;
}

.form-control+.input-group-addon {
    background-color: #FFFFFF;
}

.has-success .input-group-addon,
.has-success .form-control {
    border-color: #E3E3E3;
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-addon {
    border-color: #1be611;
}

.has-danger .form-control,
.has-danger .input-group-addon,
.has-danger.input-group-focus .input-group-addon {
    border-color: #ffcfcf;
    color: #FF3636;
    background-color: rgba(222, 222, 222, 0.1);
}

.has-danger .form-control:focus,
.has-danger .input-group-addon:focus,
.has-danger.input-group-focus .input-group-addon:focus {
    background-color: #FFFFFF;
}

.has-success:after,
.has-danger:after {
    font-family: 'Nucleo Outline';
    content: "\ea22";
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: #18ce0f;
    font-size: 11px;
}

.has-success.form-control-lg:after,
.has-danger.form-control-lg:after {
    font-size: 13px;
    top: 24px;
}

.has-success.has-label:after,
.has-danger.has-label:after {
    top: 35px;
}

.has-success .form-control+label,
.has-success.form-check .form-check-label label,
.has-success.form-check:after,
.has-danger .form-control+label,
.has-danger.form-check .form-check-label label,
.has-danger.form-check:after {
    display: none !important;
}

.has-success.form-check .form-check-label,
.has-danger.form-check .form-check-label {
    color: #18ce0f;
}

.has-danger:after {
    content: "\ea53";
    color: #FF3636;
}

.has-danger.form-check .form-check-label {
    color: #FF3636;
}

.form-group.no-border.form-control-lg .input-group-addon,
.input-group.no-border.form-control-lg .input-group-addon {
    padding: 15px 0 15px 19px;
}

.form-group.no-border.form-control-lg .form-control,
.input-group.no-border.form-control-lg .form-control {
    padding: 15px 19px;
}

.form-group.no-border.form-control-lg .form-control+.input-group-addon,
.input-group.no-border.form-control-lg .form-control+.input-group-addon {
    padding: 15px 19px 15px 0;
}

.form-group.form-control-lg .form-control,
.input-group.form-control-lg .form-control {
    padding: 14px 18px;
}

.form-group.form-control-lg .form-control+.input-group-addon,
.input-group.form-control-lg .form-control+.input-group-addon {
    padding: 14px 18px 14px 0;
}

.form-group.form-control-lg .input-group-addon,
.input-group.form-control-lg .input-group-addon {
    padding: 14px 0 15px 18px;
}

.form-group.form-control-lg .input-group-addon+.form-control,
.input-group.form-control-lg .input-group-addon+.form-control {
    padding: 15px 18px 15px 16px;
}

.form-group.no-border .form-control,
.input-group.no-border .form-control {
    padding: 11px 19px;
}

.form-group.no-border .form-control+.input-group-addon,
.input-group.no-border .form-control+.input-group-addon {
    padding: 11px 19px 11px 0;
}

.form-group.no-border .input-group-addon,
.input-group.no-border .input-group-addon {
    padding: 11px 0 11px 19px;
}


/*.form-group .form-control,
.input-group .form-control {
  padding: 10px 18px 10px 18px; }*/

.form-group .form-control+.input-group-addon,
.input-group .form-control+.input-group-addon {
    padding: 10px 18px 10px 0;
}

.form-group .input-group-addon,
.input-group .input-group-addon {
    padding: 10px 0 10px 18px;
}

.form-group .input-group-addon+.form-control,
.form-group .input-group-addon~.form-control,
.input-group .input-group-addon+.form-control,
.input-group .input-group-addon~.form-control {
    padding: 10px 19px 11px 16px;
}

.form-group.no-border .form-control,
.form-group.no-border .form-control+.input-group-addon,
.input-group.no-border .form-control,
.input-group.no-border .form-control+.input-group-addon {
    background-color: rgba(222, 222, 222, 0.3);
    border: medium none;
}

.form-group.no-border .form-control:focus,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control:active,
.form-group.no-border .form-control+.input-group-addon:focus,
.form-group.no-border .form-control+.input-group-addon:active,
.form-group.no-border .form-control+.input-group-addon:active,
.input-group.no-border .form-control:focus,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control:active,
.input-group.no-border .form-control+.input-group-addon:focus,
.input-group.no-border .form-control+.input-group-addon:active,
.input-group.no-border .form-control+.input-group-addon:active {
    border: medium none;
    background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .form-control:focus+.input-group-addon,
.input-group.no-border .form-control:focus+.input-group-addon {
    background-color: rgba(222, 222, 222, 0.5);
}

.form-group.no-border .input-group-addon,
.input-group.no-border .input-group-addon {
    background-color: rgba(222, 222, 222, 0.3);
    border: none;
}

.has-error .form-control-feedback,
.has-error .control-label {
    color: #FF3636;
}

.has-success .form-control-feedback,
.has-success .control-label {
    color: #18ce0f;
}

.input-group-addon {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 30px;
    color: #555555;
    padding: -0.5rem 0 -0.5rem -0.3rem;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.has-danger.input-group-focus .input-group-addon {
    background-color: #FFFFFF;
}

.has-success .input-group-addon {
    background-color: #FFFFFF;
}

.has-danger .form-control:focus+.input-group-addon {
    color: #FF3636;
}

.has-success .form-control:focus+.input-group-addon {
    color: #18ce0f;
}

.input-group-addon+.form-control,
.input-group-addon~.form-control {
    padding: -0.5rem 0.7rem;
    padding-left: 18px;
}

.input-group-addon i {
    width: 17px;
}

.input-group-focus .input-group-addon {
    background-color: #FFFFFF;
    border-color: #f96332;
}

.input-group-focus.no-border .input-group-addon {
    background-color: rgba(222, 222, 222, 0.5);
}

.input-group,
.form-group {
    margin-bottom: 10px;
    position: relative;
}

.input-group .form-control-static,
.form-group .form-control-static {
    margin-top: 9px;
}

.input-group[disabled] .input-group-addon {
    background-color: #E3E3E3;
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child) {
    border-left: 0 none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #E3E3E3;
    color: #888;
    cursor: not-allowed;
}

.input-group-btn .btn {
    border-width: 1px;
    padding: 11px 0.7rem;
}

.input-group-btn .btn-default:not(.btn-fill) {
    border-color: #DDDDDD;
}

.input-group-btn:last-child>.btn {
    margin-left: 0;
}

textarea.form-control {
    max-width: 100%;
    max-height: 80px;
    padding: 10px 10px 0 0;
    resize: none;
    border: none;
    border-bottom: 1px solid #E3E3E3;
    border-radius: 0;
    line-height: 2;
}

textarea.form-control:focus,
textarea.form-control:active {
    border-left: none;
    border-top: none;
    border-right: none;
}

.has-success.form-group .form-control,
.has-success.form-group.no-border .form-control,
.has-danger.form-group .form-control,
.has-danger.form-group.no-border .form-control {
    padding-right: 40px;
}

.form.form-newsletter .form-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
}

.input-group .input-group-btn {
    padding: 0 12px;
}

.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.form-text {
    font-size: 0.8571em;
}


/* .form-control-lg {
  padding: 0;
  font-size: inherit;
  line-height: 0;
  border-radius: 0; } */

.form-horizontal .col-form-label,
.form-horizontal .label-on-right {
    padding: 10px 5px 0 15px;
    text-align: right;
    max-width: 180px;
}

.form-horizontal .checkbox-radios {
    margin-bottom: 15px;
}

.form-horizontal .checkbox-radios .form-check:first-child {
    margin-top: 8px;
}

.form-horizontal .label-on-right {
    text-align: left;
    padding: 10px 15px 0 5px;
}

.form-horizontal .form-check-inline {
    margin-top: 6px;
}

button,
input,
optgroup,
select,
textarea {
    font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h1,
.h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 30px;
}

h1 small,
.h1 small {
    font-weight: 700;
    text-transform: uppercase;
    opacity: .8;
}

h2,
.h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
}

h3,
.h3 {
    font-size: 2em;
    margin-bottom: 30px;
    line-height: 1.4em;
}

h4,
.h4 {
    font-size: 1.514em;
    line-height: 1.45em;
    margin-top: 30px;
    margin-bottom: 15px;
}

h4+.category,
h4.title+.category,
.h4+.category,
.h4.title+.category {
    margin-top: -10px;
}

h5,
.h5 {
    font-size: 1.57em;
    line-height: 1.4em;
    margin-bottom: 15px;
}

h6,
.h6 {
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

p.description {
    font-size: 1.14em;
}

.title {
    font-weight: 700;
}

.title.title-up {
    text-transform: uppercase;
}

.title.title-up a {
    color: #2c2c2c;
    text-decoration: none;
}

.title+.category {
    margin-top: -10px;
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
    color: #9A9A9A;
    font-weight: 300;
}

.category,
.card-category {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 0.7142em;
}

.card-category {
    font-size: 1em;
}

#app {
    background-color: #fff;
}

.main {
    position: relative;
    background: #FFFFFF;
}

.dropdown-toggle:after,
.caret {
    -webkit-transition: all 150ms ease 0s;
    -moz-transition: all 150ms ease 0s;
    -o-transition: all 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s;
}

table .caret {
    color: #004085 !important;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.button-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #FFFFFF;
}

.button-bar+.button-bar {
    margin-top: 7px;
}

.button-bar:nth-child(2) {
    width: 17px;
}

.form-check {
    margin-top: .5rem;
}

.form-check .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    transition: color 0.3s linear;
}

.radio .form-check-sign {
    padding-left: 28px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 0;
    cursor: pointer;
    border-radius: 3px;
    top: 0;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check .form-check-sign::after {
    font-family: 'Nucleo Outline';
    content: "\ea22";
    top: 0px;
    text-align: center;
    font-size: 14px;
    opacity: 0;
    color: #555555;
    border: 0;
    background-color: inherit;
}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {
    color: #9A9A9A;
    opacity: .5;
    cursor: not-allowed;
}

.form-check input[type="checkbox"],
.radio input[type="radio"] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-check input[type="checkbox"]:checked+.form-check-sign::after {
    opacity: 1;
}

.form-control input[type="checkbox"]:disabled+.form-check-sign::before,
.checkbox input[type="checkbox"]:disabled+.form-check-sign::after {
    cursor: not-allowed;
}

.form-check input[type="checkbox"]:disabled+.form-check-sign,
.form-check input[type="radio"]:disabled+.form-check-sign {
    pointer-events: none;
}

.sel_search_multi_select {
    min-height: 40px !important
}

.form-check-radio .form-check-sign::before,
.form-check-radio .form-check-sign::after {
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #E3E3E3;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 3px;
    padding: 1px;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check-radio input[type="radio"]+.form-check-sign:after,
.form-check-radio input[type="radio"] {
    opacity: 0;
}

.form-check-radio input[type="radio"]:checked+.form-check-sign::after {
    width: 4px;
    height: 4px;
    background-color: #555555;
    border-color: #555555;
    top: 11px;
    left: 11px;
    opacity: 1;
}

.form-check-radio input[type="radio"]:checked+.form-check-sign::after {
    opacity: 1;
}

.form-check-radio input[type="radio"]:disabled+.form-check-sign {
    color: #9A9A9A;
}

.form-check-radio input[type="radio"]:disabled+.form-check-sign::before,
.form-check-radio input[type="radio"]:disabled+.form-check-sign::after {
    color: #9A9A9A;
}

.bg-default {
    background-color: #888 !important;
}

.bg-primary {
    background-color: #f96332 !important;
}

.bg-info {
    background-color: #2CA8FF !important;
}

.bg-success {
    background-color: #18ce0f !important;
}

.bg-danger {
    background-color: #FF3636 !important;
}

.bg-warning {
    background-color: #FFB236 !important;
}

.bg-white {
    background-color: #FFFFFF !important;
}

img {
    max-width: 100%;
    border-radius: 0.1875rem;
}

.img-raised {
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}


/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
    font-family: 'Nucleo Outline';
    src: url("../fonts/nucleo-outline.eot");
    src: url("../fonts/nucleo-outline.eot") format("embedded-opentype"), url("../fonts/nucleo-outline.woff2") format("woff2"), url("../fonts/nucleo-outline.woff") format("woff"), url("../fonts/nucleo-outline.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


/*------------------------
	base class definition
-------------------------*/

.now-ui-icons {
    display: inline-block;
    font: normal normal normal 14px/1 'Nucleo Outline';
    font-size: inherit;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/*------------------------
  change icon size
-------------------------*/


/*----------------------------------
  add a square/circle background
-----------------------------------*/

.now-ui-icons.circle {
    padding: 0.33333333em;
    vertical-align: -16%;
    background-color: #eee;
}

.now-ui-icons.circle {
    border-radius: 50%;
}


/*------------------------
  list icons
-------------------------*/

.nc-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}

.nc-icon-ul>li {
    position: relative;
}

.nc-icon-ul>li>.now-ui-icons {
    position: absolute;
    left: -1.57142857em;
    top: 0.14285714em;
    text-align: center;
}

.nc-icon-ul>li>.now-ui-icons.circle {
    top: -0.19047619em;
    left: -1.9047619em;
}


/*------------------------
  spinning icons
-------------------------*/

.now-ui-icons.spin {
    -webkit-animation: nc-icon-spin 2s infinite linear;
    -moz-animation: nc-icon-spin 2s infinite linear;
    animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes nc-icon-spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes nc-icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/*------------------------
  rotated/flipped icons
-------------------------*/


/*------------------------
	font icons
-------------------------*/

.now-ui-icons.ui-1_check:before {
    content: "\ea22";
}

.now-ui-icons.ui-1_email-85:before {
    content: "\ea2a";
}

.now-ui-icons.arrows-1_cloud-download-93:before {
    content: "\ea21";
}

.now-ui-icons.arrows-1_cloud-upload-94:before {
    content: "\ea24";
}

.now-ui-icons.arrows-1_refresh-69:before {
    content: "\ea44";
}

.now-ui-icons.arrows-1_share-66:before {
    content: "\ea4c";
}

.now-ui-icons.business_chart-bar-32:before {
    content: "\ea1e";
}

.now-ui-icons.business_chart-pie-36:before {
    content: "\ea1f";
}

.now-ui-icons.design_app:before {
    content: "\ea08";
}

.now-ui-icons.design_bullet-list-67:before {
    content: "\ea14";
}

.now-ui-icons.design_image:before {
    content: "\ea33";
}

.now-ui-icons.design_palette:before {
    content: "\ea41";
}

.now-ui-icons.design_scissors:before {
    content: "\ea4a";
}

.now-ui-icons.design-2_html5:before {
    content: "\ea32";
}

.now-ui-icons.design-2_ruler-pencil:before {
    content: "\ea48";
}

.now-ui-icons.files_box:before {
    content: "\ea12";
}

.now-ui-icons.files_paper:before {
    content: "\ea43";
}

.now-ui-icons.files_single-copy-04:before {
    content: "\ea52";
}

.now-ui-icons.ui-1_settings-gear-63:before {
    content: "\ea4e";
}

.now-ui-icons.ui-1_simple-remove:before {
    content: "\ea53";
}

.now-ui-icons.ui-2_chat-round:before {
    content: "\ea20";
}

.now-ui-icons.ui-2_favourite-28:before {
    content: "\ea2b";
}

.now-ui-icons.ui-2_like:before {
    content: "\ea37";
}

.now-ui-icons.ui-2_settings-90:before {
    content: "\ea4b";
}

.now-ui-icons.users_circle-08:before {
    content: "\ea23";
}

.now-ui-icons.users_single-02:before {
    content: "\ea51";
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 0.1875rem;
    margin: 15px 0;
    min-height: 168px;
}

.all-icons [class*="now-ui-icons"] {
    font-size: 32px;
}

.all-icons .font-icon-detail p {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #B8B8B8;
    padding: 0 10px;
    font-size: 0.7142em;
}

.table .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.table .img-row {
    max-width: 60px;
    width: 60px;
}

.table .form-check {
    margin: 0;
}

.table .form-check label .form-check-sign::before,
.table .form-check label .form-check-sign::after {
    top: -17px;
    left: 4px;
}

.table .btn {
    margin: 0;
}

.table small,
.table .small {
    font-weight: 300;
}

.card-tasks .card-body .table {
    margin-bottom: 0;
}

.card-tasks .card-body .table>thead>tr>th,
.card-tasks .card-body .table>tbody>tr>th,
.card-tasks .card-body .table>tfoot>tr>th,
.card-tasks .card-body .table>thead>tr>td,
.card-tasks .card-body .table>tbody>tr>td,
.card-tasks .card-body .table>tfoot>tr>td {
    padding-top: 0;
    padding-bottom: 0;
}

.table .radio,
.table .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;
}

.table .radio .icons,
.table .checkbox .icons {
    position: relative;
}

.table .radio label:after,
.table .radio label:before,
.table .checkbox label:after,
.table .checkbox label:before {
    top: -17px;
    left: -3px;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    vertical-align: middle;
    text-align: center
}

.table .th-description {
    max-width: 150px;
}

.table .td-price {
    font-size: 26px;
    font-weight: 300;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
}

.table .td-total {
    font-weight: 700;
    font-size: 1.57em;
    padding-top: 20px;
    text-align: right;
}

.table .td-actions .btn {
    margin: 0px;
}

.table>tbody>tr {
    position: relative;
}

#tables .table-responsive {
    margin-bottom: 30px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

.wrapper.wrapper-full-page {
    min-height: 100vh;
    height: auto;
}

.exp-config-file-upload {
    display: none;
}

.config-pagination {
    margin-bottom: 0;
    position: relative;
    margin-top: -10px;
    left: 15%;
}

.configExcep_detail_label, .detail_info {
    line-height: 30px;
    font-weight: 500;
    color: #929292 !important;
    margin: 0;
}

.detail_info {
    display: inline-block;
    word-break: break-word;
}

.detail_label {
    font-weight: 600;
    margin: 0;
}

.card {
    border: 0;
    /* border-radius: 0.1875rem; */
    display: inline-block;
    position: relative;
    width: 100%;
    /* margin-bottom: 20px; */
    /* box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);  */
}

.card .card-body {
    padding: 5px 15px
}

.card .card-body.table-full-width {
    padding-left: 0;
    padding-right: 0;
}

.card .card-header {
    /*padding: 15px 15px 0;*/
    /* background: linear-gradient(grey, #3a3838); */
    background: #1c4677;
    background: linear-gradient(#2a5788, #051d3a);
    text-align: left;
    padding: 1px 5px;
    border: 0;
}


/*.card .card-header:not([data-background-color]) {
      background-color: transparent; }*/

.card .card-header .card-title {
    color: #fff;
    display: inline-block;
    margin-top: 10px;
    margin-left: 5px;
}

.card .card-header .card-title-icon {
    color: #fff;
    display: inline-block;
    font-size: 25px;
    margin-left: 5px;
}

.card.card-wizard .card-header {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
}

.card .map {
    height: 500px;
    border-radius: 0.1875rem;
}

.card .map.map-big {
    height: 400px;
}

.card[data-background-color="orange"] {
    background-color: #f96332;
}

.card[data-background-color="orange"] .card-header {
    background-color: #f96332;
}

.card[data-background-color="orange"] .card-footer .stats {
    color: #FFFFFF;
}

.card[data-background-color="red"] {
    background-color: #FF3636;
}

.card[data-background-color="yellow"] {
    background-color: #FFB236;
}

.card[data-background-color="blue"] {
    background-color: #2CA8FF;
}

.card[data-background-color="green"] {
    background-color: #18ce0f;
}

.card .image {
    overflow: hidden;
    height: 200px;
    position: relative;
}

.card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
}

.card .info-area {
    padding: 40px 0 40px;
    text-align: center;
    position: relative;
    z-index: 2;
}

.card label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #525151;
}

.card .ct-chart {
    margin: 30px 0 30px;
}

.card .card-footer {
    background-color: transparent;
    border: 0;
}

.card .card-footer .stats i {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.card .card-footer .btn {
    margin: 0;
}

.card-user .image {
    height: 120px;
}

.card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: -77px;
}

.card-user .author a+p.description {
    margin-top: -7px;
}

.card-user .avatar {
    width: 124px;
    height: 124px;
    border: 1px solid #FFFFFF;
    position: relative;
}

.card-user .card-body {
    min-height: 240px;
}

.card-user hr {
    margin: 5px 15px;
}

.card-user .button-container {
    margin-bottom: 6px;
    text-align: center;
}

.card-plain {
    background: transparent;
    box-shadow: none;
}

.card-plain .card-header,
.card-plain .card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
}

.card-plain:not(.card-subcategories).card-body {
    padding-left: 0;
    padding-right: 0;
}

.card-chart .card-header .card-title {
    margin-top: 10px;
    margin-bottom: 0;
}

.card-chart .card-header .card-category {
    margin-bottom: 5px;
}

.card-chart .table {
    margin-bottom: 0;
}

.card-chart .table td {
    border-top: none;
    border-bottom: 1px solid #e9ecef;
}

.card-chart .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
}

.card-chart .card-footer {
    margin-top: 15px;
}

.card-chart .card-footer .stats {
    color: #9A9A9A;
}

.card-chart .dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
}

.card-chart .dropdown .btn {
    margin: 0;
}

.page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: #FFFFFF;
    position: relative;
}

.page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
}

.page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.page-header .container {
    height: 100%;
    z-index: 1;
}

.page-header .category,
.page-header .description {
    color: rgba(255, 255, 255, 0.8);
}

.page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px;
}

.page-header.page-header-mini {
    min-height: 40vh;
    max-height: 340px;
}

.page-header .title {
    margin-bottom: 15px;
}

.page-header .title+h4 {
    margin-top: 10px;
}

.page-header:after,
.page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}

.page-header:before {
    background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 991px) {
    .profile-photo .profile-photo-small {
        margin-left: -2px;
    }
    .button-dropdown {
        display: none;
    }
    .wrapper {
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .toggled .bar1 {
        top: 6px;
        -webkit-animation: topbar-x 500ms linear 0s;
        -moz-animation: topbar-x 500ms linear 0s;
        animation: topbar-x 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .toggled .bar2 {
        opacity: 0;
    }
    .toggled .bar3 {
        bottom: 6px;
        -webkit-animation: bottombar-x 500ms linear 0s;
        -moz-animation: bottombar-x 500ms linear 0s;
        animation: bottombar-x 500ms 0s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    @keyframes topbar-x {
        0% {
            top: 0px;
            transform: rotate(0deg);
        }
        45% {
            top: 6px;
            transform: rotate(145deg);
        }
        75% {
            transform: rotate(130deg);
        }
        100% {
            transform: rotate(135deg);
        }
    }
    @-webkit-keyframes topbar-x {
        0% {
            top: 0px;
            -webkit-transform: rotate(0deg);
        }
        45% {
            top: 6px;
            -webkit-transform: rotate(145deg);
        }
        75% {
            -webkit-transform: rotate(130deg);
        }
        100% {
            -webkit-transform: rotate(135deg);
        }
    }
    @-moz-keyframes topbar-x {
        0% {
            top: 0px;
            -moz-transform: rotate(0deg);
        }
        45% {
            top: 6px;
            -moz-transform: rotate(145deg);
        }
        75% {
            -moz-transform: rotate(130deg);
        }
        100% {
            -moz-transform: rotate(135deg);
        }
    }
    @keyframes topbar-back {
        0% {
            top: 6px;
            transform: rotate(135deg);
        }
        45% {
            transform: rotate(-10deg);
        }
        75% {
            transform: rotate(5deg);
        }
        100% {
            top: 0px;
            transform: rotate(0);
        }
    }
    @-webkit-keyframes topbar-back {
        0% {
            top: 6px;
            -webkit-transform: rotate(135deg);
        }
        45% {
            -webkit-transform: rotate(-10deg);
        }
        75% {
            -webkit-transform: rotate(5deg);
        }
        100% {
            top: 0px;
            -webkit-transform: rotate(0);
        }
    }
    @-moz-keyframes topbar-back {
        0% {
            top: 6px;
            -moz-transform: rotate(135deg);
        }
        45% {
            -moz-transform: rotate(-10deg);
        }
        75% {
            -moz-transform: rotate(5deg);
        }
        100% {
            top: 0px;
            -moz-transform: rotate(0);
        }
    }
    @keyframes bottombar-x {
        0% {
            bottom: 0px;
            transform: rotate(0deg);
        }
        45% {
            bottom: 6px;
            transform: rotate(-145deg);
        }
        75% {
            transform: rotate(-130deg);
        }
        100% {
            transform: rotate(-135deg);
        }
    }
    @-webkit-keyframes bottombar-x {
        0% {
            bottom: 0px;
            -webkit-transform: rotate(0deg);
        }
        45% {
            bottom: 6px;
            -webkit-transform: rotate(-145deg);
        }
        75% {
            -webkit-transform: rotate(-130deg);
        }
        100% {
            -webkit-transform: rotate(-135deg);
        }
    }
    @-moz-keyframes bottombar-x {
        0% {
            bottom: 0px;
            -moz-transform: rotate(0deg);
        }
        45% {
            bottom: 6px;
            -moz-transform: rotate(-145deg);
        }
        75% {
            -moz-transform: rotate(-130deg);
        }
        100% {
            -moz-transform: rotate(-135deg);
        }
    }
    @keyframes bottombar-back {
        0% {
            bottom: 6px;
            transform: rotate(-135deg);
        }
        45% {
            transform: rotate(10deg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            bottom: 0px;
            transform: rotate(0);
        }
    }
    @-webkit-keyframes bottombar-back {
        0% {
            bottom: 6px;
            -webkit-transform: rotate(-135deg);
        }
        45% {
            -webkit-transform: rotate(10deg);
        }
        75% {
            -webkit-transform: rotate(-5deg);
        }
        100% {
            bottom: 0px;
            -webkit-transform: rotate(0);
        }
    }
    @-moz-keyframes bottombar-back {
        0% {
            bottom: 6px;
            -moz-transform: rotate(-135deg);
        }
        45% {
            -moz-transform: rotate(10deg);
        }
        75% {
            -moz-transform: rotate(-5deg);
        }
        100% {
            bottom: 0px;
            -moz-transform: rotate(0);
        }
    }
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        right: 0;
        left: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .section-nucleo-icons .icons-container {
        margin-top: 65px;
    }
}

@media screen and (max-width: 768px) {
    .card-stats [class*="col-"] .statistics::after {
        display: none;
    }
    .landing-page .section-story-overview .image-container:nth-child(2) {
        margin-left: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 576px) {
    .card-contributions .card-stats {
        flex-direction: column;
    }
    .card-contributions .card-stats .bootstrap-switch {
        margin-bottom: 15px;
    }
    .section-nucleo-icons .icons-container i {
        font-size: 30px;
    }
    .section-nucleo-icons .icons-container i:nth-child(6) {
        font-size: 48px;
    }
    .page-header .container h6.category-absolute {
        width: 90%;
    }
    h4 {
        font-size: 1rem;
    }
}

.bar1,
.bar2,
.bar3 {
    outline: 1px solid transparent;
}

.bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.bar2 {
    opacity: 1;
}

.bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

::-webkit-input-placeholder {
    text-align: left;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: left;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: left;
}

:-ms-input-placeholder {
    text-align: center;
}

.cle-brand {
    padding-top: 18px !important;
    transform: translateX(10px) !important;
}

.fa-stack-2x {
    font-size: 1.7em !important;
}

.fa-stack-1x {
    font-size: 8px !important;
}

.cle-ipbox {
    height: 70% !important;
}

.cle-formgroup {
    height: 34px !important;
    margin-bottom: 5px;
}

.error-btn {
    float: right !important;
}

.cle-ht-box {
    height: 34px !important;
}

.loader {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.23);
    border-radius: 50%;
    padding-top: 9px;
    margin: 50px auto;
}

.cle-header-toggle {
    font-size: 12px;
    display: inline-block;
    line-height: 25px;
    padding-right: 20px;
    /* top: -4px; */
    position: relative;
    width: 120px;
}

.cle-norecord-msg {
    text-align: center;
}

.cle-norecord-head {
    background-color: #fff;
    padding: 100px 50px;
    height: 80vh;
    position: relative;
}

.cle-norecord-btn {
    position: absolute;
    bottom: 0;
}

.time_header {
    position: absolute;
    right: 25px;
    font-size: 0.65rem;
    bottom: 0;
    /* opacity: 0.5; */
    font-weight: 600;
    color: #044c03;
    top: 50px;
    height: 0;
    line-height: 12px;
    text-align: right;
}

#modal-logout-content {
    padding: 15px;
    font-size: 13px;
    font-weight: 600;
}

.timezone-disable {
    opacity: 0.6;
}

#modal-logout-content input[type="SUBMIT"] {
    float: right;
    background-color: #2a5788;
    border: 0;
    padding: 5px 10px;
    color: #fff;
    margin-top: 15px;
}

::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.react-dropdown-select-dropdown-handle {
    margin: 5px 0 0 5px !important;
}

.css-46gck8-DropdownHandleComponent {
    margin: 0px 0 5px 5px !important;
}