.select-group-for-user-create .ant-modal-body{
    min-height: 55vh;
}

.select-group-for-user-create .line-item-error{
    margin: 0px;
}

.select-group-for-user-create .error-item-msg{
    margin-bottom: 5px;
}