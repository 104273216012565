@import "../../../assets/scss/base.scss";

.cleModal,
.dashboardModal {
  display: block !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  .detailed-view {
    font-size: $font-size-xs;
    color: #0d7698 !important;
    cursor: pointer;
  }

  .marginBottom0 {
    margin-bottom: 0;
  }

  label,
  textarea {
    font-size: $input-text-font-size;
    word-break: break-all;
  }
}

.modal-body {
  max-height: 450px;
  overflow-y: auto;
}

.progress-bar-cle {
  height: 5px !important;
  border-radius: 0px !important;
}

.errorModal {
  width: 55% !important;
}