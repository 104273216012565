@import '../../../assets/scss/base.scss';
.cle-ag-grid-wrapper{
    margin-top: 10px;
}
.cle-ag-grid-wrapper.ag-theme-material {
    font-family: $primary-font-family;

    .ag-paging-panel {
        height: 40px;
        font-size: $table-header-text-font-size;
        font-weight: $table-header-text-font-weight;
    }
    .ag-root-wrapper {
        border-radius: 5px;
        box-shadow: 0 0px 4px 0px $table-header-box-shadow;
    }
    .ag-checkbox-input-wrapper,
    .ag-header-cell-label {
        font-size: $table-header-text-font-size + 1;
    }
    .ag-paging-panel {
        color: $green-100;
    }

    .ag-header {
        box-shadow: 0 2px 9px 0px $table-header-box-shadow;
        z-index: 0;
        //border-bottom: 0;
        &-row {
            color: $table-header-text-color;
        }
    }

    .ag-row {
        color: $table-row-text-color;
    }

    .ag-cell {
        line-height: 33px;
    }

    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
        height: 33px;
    }

    .ag-header-icon, .ag-sort-indicator-icon {
        color: $table-icon-color;
    }

    .ag-checkbox-input-wrapper.ag-checked:after,
    .ag-radio-button-input-wrapper.ag-checked::after {
        color: $checkbox-radio-color;
    }
}