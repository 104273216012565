@import "../../../assets/scss/base.scss";

.nav-tabs .nav-link.active {
  font-weight: bold;
  background-color: $tabs-nav-active-bg;
  color: $tabs-nav-active-text-color;

  &:hover {
    background-color: #4375ab;
    color: $tabs-nav-active-text-color;
  }
}

.ag-grid-margin {
  margin-top: 36px;
}

.application-button {
  margin-top: 11px;
  margin-left: 0px;
}

.align-button {
  margin-top: 23px;
  margin-left: -7px;
}

.application-title {
  font-size: 15px !important;
}

.list-select {
  height: 90% !important;
}

.applications-tab {

  select,
  input[type=text] {
    height: 85% !important;
    width: 85% !important;
  }

  .btn-add {
    padding: 7px 16px;
  }

  label {
    margin-left: 25px;
  }
}

.select-table {
  width: 261px;
}

.required-field::after {
  content: "*";
  color: $app-error-color;
}

.select-list {
  z-index: 999 !important;
}

.admin-list-choose-application-wrapper {
  margin: 15px 10px;
}

.list-footer-wrapper {
  margin-top: 15px;
  display: flex;
  place-content: flex-end;
}