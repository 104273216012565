@import "../../../assets/scss/base.scss";


.replay-config-detail-modal{
    .ant-row{
        margin-bottom: 15px;
    }
    .passwordLabelWrapper {
        .ant-input-password {
            padding-left: 0;
        }
    }
}