@import "../../assets/scss/base.scss";

.no_role_select {
    font-size: $font-size-xl;
    color: $gray-600;
    margin: auto;
    text-align: center;
    margin-top: 5%;
}

.group-access-tab-with-controls {
    &.ant-tabs-top>.ant-tabs-nav::before {
        width: 75%;
    }
    .ant-tabs-extra-content {
        width: 22%;
        margin-right: 10px;
    }
}

.application-access-tab-with-controls {
    &.ant-tabs-top>.ant-tabs-nav::before {
        width: 74%;
    }
    .ant-tabs-extra-content {
        width: 23%;
    }
}