@import './assets/scss/base.scss';

input[type="checkbox"]:focus {
    outline: 0;
}

#jsonInputEdit {
    font-size: $font-size-md !important;
}

.fixed-progress-bar {
    top: 65px !important;
    z-index: 5 !important;
}

.out-line-none {
    outline: none;
}

.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    margin: auto;
    text-align: center;
    padding: 16px 0 14px;
    height: 72px;
}

.ant-menu-inline-collapsed>.ant-menu-item .anticon+span {
    font-size: 0.8em;
    left: 0;
    display: block;
    max-width: 100%;
    opacity: 1;
    white-space: break-spaces;
    line-height: 18px;
    /* margin-top: 5px; */
}

.ant-menu-submenu-title .anticon {
    min-width: 20px;
    margin-right: 20px;
    font-size: 18px;
}

.ant-menu-dark.ant-menu-vertical .ant-menu-item {
    text-align: center;
    padding: 0px 0px 5px 0;
    margin: 0;
    height: auto;
}

.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 20px;
    line-height: 40px;
}

.ant-layout-sider-collapsed .menuContainer {
    padding-top: 0 !important;
}

.ant-menu.ant-menu-dark {
    color: #d4d4d4;
    background-color: #112c44;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: #d4d4d4;
    background-color: #112c44;
}

.ant-layout-sider {
    background-color: #023a6b;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #112c44;
}

.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: $header-item-text-color;
    background-color: #0000004a;
}

.ant-open-menu .ant-collapse {
    padding-bottom: 0.02px;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
    padding: 0;
}

.ant-open-menu .ant-collapse-header {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
    color: #d4d4d4 !important;
}

.ant-open-menu .anticon {
    min-width: 20px;
    margin-right: 20px;
    font-size: 18px;
}

.ant-layout-sider-collapsed .ant-collapse-header {
    margin: auto;
    text-align: center;
    padding: 0px !important;
    height: 75px;
    color: #d4d4d4 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    align-items: center;
}

.ant-layout-sider-collapsed .anticon {
    margin: 0 !important;
    font-size: 20px !important;
    line-height: 0 !important;
}

.ant-layout-sider-collapsed .menu-title {
    font-size: 0.8em;
    left: 0;
    display: block;
    line-height: 30px;
    max-width: 100%;
    opacity: 1;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #9e9e9e8a;
}

.ant-collapse-header:hover {
    background-color: #7b7b7b8c;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 16px;
    padding-right: 40px;
}

.cle-new-font {
    font-family: $primary-font-family;
}

body {
    font-size: $font-size-md;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    background-color: #f8f8f8;
    color: $app-text-color;
    font-family: $primary-font-family;
}

a {
    text-decoration: none !important;
}

.header {
    padding: 0;
    box-shadow: 3px 2px 2px #0808082b;
    position: relative;
    z-index: 9;
    align-items: center;
    background-color: $header-bg-color;
    width: 100%;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: #3f51b5 !important;
}

.secondary {
    background-color: #18ce0f;
    color: #ffffff;
    border-color: #18ce0f;
}

.secondary.ant-btn-primary:hover,
.secondary.ant-btn-primary:focus {
    background-color: #18ce0f;
    color: #ffffff;
    border-color: #18ce0f;
}
.secondary.ant-btn-primary[disabled],.secondary.ant-btn-primary[disabled]:active,.secondary.ant-btn-primary[disabled]:focus,.secondary.ant-btn-primary[disabled]:hover {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}
.mid-black {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.mid-black.ant-btn-primary:hover,
.mid-black.ant-btn-primary:focus {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.blackBtn {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.blackBtn.ant-btn-primary:hover,
.blackBtn.ant-btn-primary:focus {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
    top: 53%;
}

.ant-form-item-label>label.ant-form-item-required::before {
    position: absolute;
    right: -3px;
}

.ant-modal-body {
    max-height: 75vh;
    overflow: auto;
}

.flt-left {
    float: left;
}

.ant-modal-header {
    padding: 12px 24px;
}

.ant-form-item {
    margin-bottom: 0;
}

.b600 {
    font-weight: 600 !important;
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
    border-top: 1px solid #d6d6d6;
}

.ant-form-item-label>label {
    font-size: 1em;
    /* font-weight: 600; */
    color: #969292;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding-bottom: 0px;
}

.ant-collapse-header:hover {
    background-color: transparent;
}

.ant-modal-title {
    line-height: inherit;
}

.ant-input-affix-wrapper,
.ant-picker,
.react-dropdown-select {
    border-radius: 5px !important;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: hidden;
}

.content-wrapper {
    padding: 0 10px;
}

.ant-card-body {
    padding: 10px;
}

.search-input {
    font-weight: 300;
    border-radius: 4px;
}

.btn-tooltip {
    &.ant-tooltip {
        font-size: $font-size-sm;
    }
}