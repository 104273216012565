.logoWidth {
    width: 450px;
}

.btnWidth {
    width: 80%;
}

.errorHeader {
    font-size: 22px;
    font-weight: 500;
    color: #6c757d;
}

.mailToHref {
    color: unset !important;
    text-decoration: unset !important;
}

.mr20 {
    margin-right: 20px;
}

.mr-40 {
    margin-right: 40px;
}

.brnErr {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 115px;
}

.imgErr {
    width: 400px;
    margin: auto;
}

.mb10 {
    margin-bottom: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.closeIcon {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 35px !important;
    font-weight: 100 !important;
    border: none !important;
    outline: none !important;
}