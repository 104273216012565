@import '../../assets/scss/base.scss';

.overlay_loader {
  position: fixed;
  top: 94px;
  background-color: convertToRgba($app-bg-black);
  height: 100%;
  width: 100%;
  z-index: 99999;

  &_load {
    top: 40%;
    position: relative;
  }

  &_detail {
    position: fixed;
    background-color: convertToRgba($app-bg-black);
    height: 100%;
    width: 100%;
    z-index: 99999;
    left: 0;

    &_full {
      position: fixed;
      background-color: convertToRgba($app-bg-black);
      height: 100%;
      width: 100%;
      z-index: 99999;
      left: 0;
      top: 0
    }
  }
}