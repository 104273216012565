@import "../../assets/scss/base.scss";

.search-component-group {
    padding: 15px 0 50px 20px;

    .ant-form-item-label > label.ant-form-item-required::before {
        position: absolute;
        right: -10px;
    }
    .ant-form-large .ant-form-item-label > label {
        color: $app-text-color;
        font-weight: $font-weight-semibold;
    }
    & + .ant-row-end {
        z-index: 9;
    }
}
