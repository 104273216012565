.create-group-container {
    width: 90%;
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding-top: 0px;
    margin: auto;
    padding: 0 20px 10px;
    margin-top: 20px;
    margin-bottom: 70px;
}

.create-group-header {
    padding: 15px 0px;
    font-size: 20px;
    font-weight: 500;
    color: #9E9E9E;
}

.inline-user-list .user-header {
    font-size: 17px;
    flex-direction: row !important;
}

.inline-user-list .user-header::after {
    content: "*";
    padding: 5px;
    color: #ff4d4f;
    font-size: 14px;
    justify-content: center;
}

.inline-user-list .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 20%);
    color: #d9d9d9 !important;
}

.inline-user-list .ant-input-affix-wrapper-status-error .ant-input-prefix{
    color: #d9d9d9 !important;
}