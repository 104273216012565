@import "../../assets/scss/base.scss";

.user-footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    @include flexbox();
    justify-content: flex-end;
    background-color: $app-bg-white;
    padding: 5px 20px;
    box-shadow: -3px -1px 4px 0 rgba(135, 139, 222, .56);
    right: 0;
}