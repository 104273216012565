@import '../../../assets/scss/base.scss';

.search-box {
  margin: 10px 10px 10px;
  line-height: 30px;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: auto !important;
  height: auto !important;
}

.search-box:focus {
  outline: none;
  border: 1px solid #0074d9;
}

.cle-multi-select-placholder {
  color: #9e9e9ea1;
  font-size: $font-size-lg;
  padding-left: 5px;
  user-select: none;
}

.react-dropdown-select-dropdown {
  /* overflow: initial !important; */
  width: 100% !important;
  /* width: 358px !important; */
  font-size: 13px;
  /* font-weight: 600; */
  /* overflow: auto !important; */
}

.admin-modal-wrapper .react-dropdown-select-dropdown-position-bottom {
  top:45px;
}
.react-dropdown-select-type-multi {
  width: 80% !important;
}

.search-conatiner {
  display: flex;
  flex-direction: column;
}

.selectAll-conatiner {
  display: flex;
  justify-content: space-between;
}

.selectAll-conatiner > div:first-child {
  margin: 10px 0 0 10px;
  font-weight: $font-weight-semibold;
}

.select-drop-disabled {
  cursor: not-allowed !important;
}
.select-drop-disabled > span {
  pointer-events: none;
  opacity: 0.8;
}

.render-all {
  padding-left: 10px;
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
}

.ant-btn-sm.btn-selectAll {
  background: none;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 6px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.btn-selectAll.ant-tooltip-disabled-compatible-wrapper:hover {
  border: none;
}

.react-dropdown-select-clear {
  font-size: 20px;
}

.clear {
  color: tomato;
  border: 1px solid tomato;
}

.btn-selectAll:hover {
  border: 1px solid deepskyblue;
  color: deepskyblue;
}

.item-conatainer {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.Item {
  display: flex;
  margin: 10px;
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;
}

.Item:hover {
  border-bottom: 1px dotted #ccc;
}

.ItemLabel {
  margin: 5px 10px;
}

.select-lable {
  padding: 0 5px;
  border-radius: 2px;
  line-height: 21px;
  margin: 3px 0 3px 5px;
  background: #0074d9;
  color: $white;
  @include flexbox();
  @include flex-direction(row);
  white-space: nowrap;
}

.react-dropdown-select-option-remove {
  padding-left: 5px;
  cursor: pointer;
}

.item-overflow {
  @include flexbox();
  overflow: hidden;
  width: 100%;
}

.error {
  border: "1px solid #E91E63";
}
