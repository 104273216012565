@import "../../assets/scss/base.scss";

.ag-theme-material .redBackground {
  background-color: $table-row-error-status-bg-color;
  color: $table-row-error-status-text-color;
}
.card-height {
  min-height: 500px;
}
.fa-3x i {
  color: $gray-600;
}
.center-align {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 210px;
}
.errorMsg {
  color: $app-error-color;
}
p.details {
  font-size: $font-size-sm;
  word-wrap: break-word;
}
.jmsHeader {
  font-size: 13px !important;
}
.jms-input {
  width: 100%;
}
.card-height {
  min-height: 500px;
}

.commontable_container {
  height: calc(100vh - 200px);
  margin: 0px 5px;
}
.api_sort_container {
  position: absolute;
  z-index: 1;
  left: 15px;
  bottom: -36px;
}
.total_record_container {
  font-size: $form-label-font-size;
  font-weight: $font-weight-semibold;
  color: $table-header-label-color;
}

.table_over_loader {
  margin-top: 5px;
  color: $blue-light-100;
}
.table_header_container {
  padding: 5px;
}

.hide-search-column-toggle-wrapper{
  display: flex;
  align-items: center;
}

.hide-search-column-toggle-wrapper span{
  font-size: $form-label-font-size;
  font-weight: $font-weight-semibold;
  margin-right: 10px;
  color: $table-header-label-color;
}

.ant-popover {
  width: 450px;

  &-inner-content {
    padding: 7px;
  }
}

.audit-log{
  padding: 10px 16px;
  padding-right: 40px;
}
.audit-log span{
 color: #000000;
 font-weight: 700;
}
.audit-table tbody tr td:first-child{
  padding-left: 10px;
}
.audit-table tbody tr td:first-child{
  width: 16em !important;
}

.multi-select-wrapper {
  .sel_search_multi_select {
    min-height: 32px!important;
    border-radius: 1px!important;
    display: flex;
    flex-direction: column;

    &:focus-within {
      box-shadow: none;
    }

    .select-lable {
      height: 21px;
    }
  }

  .react-dropdown-select-type-multi {
    width: 100%!important;
    margin: 3px 0 5px;
    padding-bottom: 5px;
    border-bottom:1px solid #ccc;
  }

  .react-dropdown-select-dropdown {
    position: static!important;
    box-shadow: none;
    padding: 5px;
    border:0px;
  }

  .item-conatainer {
    max-height: 155px;
  }

  .react-dropdown-select-separator {
    display: none;
  }

  .item-overflow {
    overflow: auto;
    flex-wrap: wrap;
    height: auto;
    max-height: 110px;
  }

  .cle-multi-select-placholder {
    font-size: $form-label-font-size;
    color: $gray-700;
  }
  
  .render-all {
    color: $table-header-label-color;
  }

  .btn-selectAll {
    text-transform: capitalize;
    margin: 10px 5px 0;
    padding: 0;

    &.ant-tooltip-disabled-compatible-wrapper {
      border: none;
    }
  }

  .react-dropdown-select-dropdown-handle {
    display: none;
  }
}